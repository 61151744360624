.App {
  text-align: center;
}
.Header {
  color: #443e3e;
  text-shadow: 1px 1px 5px #fff;
}
/* write your parent styles in here for your App.js */

.card-containment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}