body {
  width: 100%;/*added*/
  height: 100%;/*added*/
  overflow: hidden;/*added*/
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url('./sw-bg.jpg');
  background-size: cover;
}
/*Added code below*/
.fade {
  position: relative;
  width: 100%;
  min-height: 60vh;
  top: -25px;
  background-image: linear-gradient(0deg, transparent, black 75%);
  z-index: 1;
}

.star-wars {
  display: flex;
  justify-content: center;
  position: relative;
  height: 800px;
  color: #feda4a;
  font-family: 'Pathway Gothic One', sans-serif;
  font-size: 500%;
  font-weight: 600;
  letter-spacing: 6px;
  line-height: 150%;
  perspective: 400px;
  text-align: justify;
}

.crawl {
  position: relative;
  top: 99999px;
  transform-origin: 50% 100%;
  animation: crawl 60s linear;
}

.crawl > .title {
  font-size: 90%;
  text-align: center;
}

.crawl > .title h1 {
  margin: 0 0 100px;
  text-transform: uppercase;
}

@keyframes crawl {
    0% {
    top: -100px;
    transform: rotateX(20deg)  translateZ(0);
  }
    100% { 
    top: -6000px;
    transform: rotateX(25deg) translateZ(-2500px);
  }
}